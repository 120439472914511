<template>
<v-container>
  <v-tabs v-model="tabs">
    <v-tab>Support</v-tab>
    <v-tab>Guides d'utilisation</v-tab>
  </v-tabs>
  <v-tabs-items v-model="tabs">
    <v-tab-item>
      <Support/>
    </v-tab-item>
    <v-tab-item>
      <div class="pl-4 pt-4" style="height: 200px">
        <span class="green--text">
          <v-icon color="green">mdi-help-circle</v-icon>
          <span>Vous pourrez bientôt retrouver ici les différents guides d'utilisation de l'application.</span>
        </span>
      </div>
    </v-tab-item>
  </v-tabs-items>
</v-container>
</template>

<script>
import Support from "@/views/Support";

export default {
  components: {Support},
  async mounted() {

  },
  data() {
    return {
      tabs: 0,
    }
  }
}
</script>

